export const Collections = {
    ClientUsers: "srv_client_users",
    BackendUsers: "srv_backend_users",
    Business: "srv_business",
    Features: "srv_features",
    Groups: "srv_groups",
    Orders: "srv_orders",
    OrdersRequest: "srv_orders_request",
    Cities: "srv_cities",
    Ping: "srv_ping",
    Errors: "srv_errors",
    Logs: "srv_logs",
    Campaigns: "prm_campaigns",
    CampaignsParts: "prm_campaigns_parts",
    GeneratedMessages: "prm_generated_messages",
    Promos: "prm_promos",
    Wallets: "prm_wallets",
    InvoiceSummary: "srv_invoice_summary",
};

export const SubCollections = {
    Products: "products",
    ProductsSettings: "products_settings",
    Templates: "templates",
    Addresses: "addresses",
    DeliveryZones: "delivery_zones",
    Settings: "settings",
    References: "references",
    Reviews: 'reviews'
};

export const OthersSubCollections = {
    Cities: "cities",
    Neighborhoods: "neighborhoods",
    Collections: "collections",
    Categories: "categories",
    SpecialClosings: "special_closings",
    Groups: "groups",
    Config: "config",
    Business: "business",
    BookTables: "booktables",
    Codes: "codes",
    Vouchers: "vouchers",
    Referrals: "referrals",
    Batches: "batches"
};

export const Features = {
    Vouchers: "vouchers",
    Fidelity: "fidelity",
    Referrals: "referrals",
    Codes: "codes",
    BookTables: "booktables"
};