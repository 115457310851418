import { Timestamp } from '@model/types/types';
import { isNil, isEmpty } from '@shared/lodash';

export function mapBy<MapKeyType extends string | number | symbol, ObjectType>(array: ObjectType[], property: string): Record<MapKeyType, ObjectType> {
    const map = {};

    if (!isNil(array)) {
        array.forEach((obj) => {
            if (!isNil(obj[property])) {
                map[obj[property]] = obj;
            }
        });
    }

    return map as Record<MapKeyType, ObjectType>;
}

export function first<T>(value: T[]): T | undefined {
    return !isEmpty(value) ? value[0] : undefined;
}

export function delay(n: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(resolve, n);
    });
}

export function setIds<T extends { id?: string }>(objs: T[], ids: string[]) {
    for (let idx = 0; idx < objs.length; idx++) {
        const obj = objs[idx];
        obj.id = ids[idx];
    }
}

export function delIds<T extends { id?: string }>(objs: T[]): string[] {
    const ids: string[] = [];
    for (let idx = 0; idx < objs.length; idx++) {
        const obj = objs[idx];
        ids.push(obj.id);
        delete obj.id;
    }

    return ids;
}

export function getRandomColor(addSharp: boolean = true): string {
    var letters = '0123456789ABCDEF';
    var color = addSharp ? '#' : '';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export function hexToGray(hex: string): string {
    // Asegurarse de que el string empiece con '#'
    if (hex.startsWith('#')) {
      hex = hex.slice(1);
    }
  
    // Convertir cada componente hexadecimal a decimal
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
  
    // Calcular el valor de gris utilizando la fórmula de luminancia
    const gray = Math.round(0.299 * r + 0.587 * g + 0.114 * b);
  
    // Convertir el valor de gris a un string hexadecimal de dos dígitos
    const grayHex = gray.toString(16).padStart(2, '0');
  
    // Retornar el color gris en formato hexadecimal
    return `#${grayHex}${grayHex}${grayHex}`;
  }

export function getAt<T>(col: T[], position: number): T | null {
    return !!col && col.length > position ? col[position] : null;
}

export function stringToDecimal(decimal: string): number {
    if (decimal) {
        const d = +(decimal.replace(',', '.'));
        return +(d.toFixed(2));
    }

    return;
}

export function decimalToString(decimal: number): string {
    if (!isNil(decimal)) {
        const str = decimal.toFixed(2);
        return str.replace('.', ',');
    }

    return;
}

export function compareNumber(n1: number, n2: number): number {
    if (n1 > n2) {
        return 1;
    }

    if (n1 < n2) {
        return -1;
    }

    return 0;
}

export function compareBoolean(b1: boolean, b2: boolean): number {
    if (b1 > b2) {
        return 1;
    }

    if (b1 < b2) {
        return -1;
    }

    return 0;
}

export function compareByDate(date1: Date | Timestamp, date2: Date | Timestamp): number {
    const date1Millis = date1 instanceof Date ? date1.getTime() : date1.toMillis();
    const date2Millis = date2 instanceof Date ? date2.getTime() : date2.toMillis();

    return compareNumber(date1Millis, date2Millis);
}

export function capitalizeWords(str: string, ...capitalizeIndex: number[]): string {
    const result = [];

    if(capitalizeIndex && capitalizeIndex.length > 0) {
        const words = str.split(" ");
        for(let i = 0; i < words.length; i++) {
            const word = words[i];
            if(capitalizeIndex.includes(i)) {
                result.push(capitalize(word));
            } else {
                result.push(word);
            }
        }
    }

    return result.join(" ");
}

export function capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function formatString(format: string, ...args: any[]) {
    // use replace to iterate over the string
    // select the match and check if related argument is present
    // if yes, replace the match with the argument
    return format.replace(/{([0-9]+)}/g, (match, index) => {
        // check if the argument is present
        return typeof args[index] == "undefined" ? match : args[index];
    });
}

export function replaceEOL(str: string): string {
    if(!str) {
        return str;
    }

    return str.replace(/\\n/g, '\n');
}

export function normalizeStr(str: string): string {
    // Si es "" retorna ""
    // Si es null retorna null
    // Si es undefined retorna undefined
    if (!str) return str;

    return str.normalize("NFKD").replace(/[\u0300-\u036f]/g, "");
}

export function delUndefined<T = any>(obj: T): T {
    for (const prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            if (obj[prop] === undefined) {
                delete obj[prop];
            }
        }
    }

    return obj;
}

export function group<T>(array: T[], predicate: (curr: T, prev: T) => boolean, orderGroup?: (elem1: T[], elem2: T[]) => number, orderItems?: (elem1: T, elem2: T) => number): T[][] {
    let _group: T[];
    let result: T[][] = [];
    let prev: T = null;

    array.forEach(m => {
        const curr = m;
        if (!prev || predicate(curr, prev)) {
            _group = [];
            result.push(_group);
        }

        _group.push(curr);
        prev = curr;
    });

    if (orderGroup) {
        result = result.sort(orderGroup);
    }

    if (orderItems) {
        for (let i = 0; i < result.length; i++) {
            result[i] = result[i].sort(orderItems);
        }
    }

    return result;
}