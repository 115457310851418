import {
    FieldPath,
    OrderByDirection,
    QueryConstraint,
    WhereFilterOp,
    limit,
    orderBy,
    startAfter,
    where,
    or,
    and,
    QueryFilterConstraint,
    QueryCompositeFilterConstraint
} from "firebase/firestore";

export type QueryFn = (queryBuilder: QueryBuilder) => QueryBuilder;

export class QueryBuilder {
    private queryConstraints: (QueryConstraint | QueryCompositeFilterConstraint)[] = [];

    public new(): QueryBuilder {
        return new QueryBuilder();
    }

    public chain(qb: QueryBuilder): QueryBuilder {
        this.queryConstraints.push(...qb.getQueryConstraints());
        return this;
    }

    public append(queryConstraintOrQueryFn: (QueryConstraint | QueryCompositeFilterConstraint)[] | QueryFn): QueryBuilder {
        let appendQueryConstraints: (QueryConstraint | QueryCompositeFilterConstraint)[];

        if (typeof queryConstraintOrQueryFn === 'function') {
            appendQueryConstraints = callQueryFn(queryConstraintOrQueryFn);
        } else {
            appendQueryConstraints = queryConstraintOrQueryFn;
        }

        this.queryConstraints.push(...appendQueryConstraints);
        return this;
    }

    public where(fieldPath: string | FieldPath, opStr: WhereFilterOp, value: unknown): QueryBuilder {
        this.queryConstraints.push(
            where(fieldPath, opStr, value)
        );
        return this;
    }

    // Método para el operador 'in'
    public whereIn(fieldPath: string | FieldPath, values: unknown[]): QueryBuilder {
        this.queryConstraints.push(
            where(fieldPath, 'in', values)
        );
        return this;
    }

    // Método para el operador 'array-contains-any'
    public whereArrayContainsAny(fieldPath: string | FieldPath, values: unknown[]): QueryBuilder {
        this.queryConstraints.push(
            where(fieldPath, 'array-contains-any', values)
        );
        return this;
    }

    // Método para combinar consultas con OR
    public or(...queryBuilders: QueryBuilder[]): QueryBuilder {
        const filterConstraints: QueryFilterConstraint[] = [];

        queryBuilders.forEach(qb => {
            const constraints = qb.getQueryConstraints();
            constraints.forEach(constraint => {
                if ('type' in constraint && (constraint.type === 'where' || constraint.type === 'or' || constraint.type === 'and')) {
                    filterConstraints.push(constraint as QueryFilterConstraint);
                }
            });
        });

        if (filterConstraints.length > 0) {
            this.queryConstraints.push(or(...filterConstraints));
        }
        return this;
    }

    // Método para combinar consultas con AND
    public and(...queryBuilders: QueryBuilder[]): QueryBuilder {
        const filterConstraints: QueryFilterConstraint[] = [];

        queryBuilders.forEach(qb => {
            const constraints = qb.getQueryConstraints();
            constraints.forEach(constraint => {
                if ('type' in constraint && (constraint.type === 'where' || constraint.type === 'or' || constraint.type === 'and')) {
                    filterConstraints.push(constraint as QueryFilterConstraint);
                }
            });
        });

        if (filterConstraints.length > 0) {
            this.queryConstraints.push(and(...filterConstraints));
        }
        return this;
    }

    public orderBy(fieldPath: string | FieldPath, directionStr?: OrderByDirection): QueryBuilder {
        this.queryConstraints.push(
            orderBy(fieldPath, directionStr)
        );
        return this;
    }

    public getQueryConstraints(): (QueryConstraint | QueryCompositeFilterConstraint)[] {
        return this.queryConstraints;
    }

    public startAfter(...fieldValues: unknown[]): QueryBuilder {
        this.queryConstraints.push(
            startAfter(...fieldValues)
        );
        return this;
    }

    public limit(_limit: number): QueryBuilder {
        this.queryConstraints.push(
            limit(_limit)
        );
        return this;
    }
}

export function callQueryFn(queryFn?: QueryFn): (QueryConstraint | QueryCompositeFilterConstraint)[] {
    if (queryFn) {
        return queryFn(new QueryBuilder()).getQueryConstraints();
    }
    return [];
}