import { LocaleInfo } from "./types/locale";

export enum E_FidelityMode {
    Group = "group",
    Business = "business"
}

export interface Group {
    id?: string;

    /**
     * Nombre público para mostrar que identifica al grupo
     * Si el group tiene un único business normalmente van a coincidir.
     */
    name: string;

    fidelity: {
        enabled: boolean;
        mode: E_FidelityMode;
    }

    /**
     * Si está habilitado o no el plan de referidos
     */
    referrals: {
        enabled: boolean;
    }

    /**
     * Si está habilitado o no el ingreso de códigos que se intercambian por vouchers.
     */
    codes: {
        enabled: boolean;
    }

    /**
     * Si la funcionalidad de mandar ESTADO está activada o no
     */
    state: {
        enabled: boolean;
    }

    /**
     * Si en el menú list se despliega una pequeña promo hacia nuestro sitio
    */
    dihola?: {
        enabled: boolean;
    }

    /**
     * Si está habilitado el envío de tips o no 
    */
    tips?: {
        enabled: boolean;
    }

    /** 
     * Si está habilitada la funcionalidad de gift
    */
    gift: {
        enabled: boolean;
    }

    /**
     * Un mensaje personalizado para el menú de ayuda
     * Si es un string[] se van a separar por \n para ser desplegados
     */
    help?: string | string[];

    /**
     * Si la funcionalidad de mandar el mensaje de "su pedido ya salió..." está habilitada o no.
     */
    finishStateMessageEnabled: {
        /**
         * Si está habilitado o no, enviar el mensaje cuando se apretó el botón de EN CAMINO desde el backend
         */
        fromBackend: boolean;

        /**
        * Si está habilitado o no, enviar el mensaje cuando se escanea el código QR del ticket (la comanda impresa)
        */
        fromQR: boolean;

        /**
        * Si está habilitado o no, enviar el mensaje cuando se finaliza el pedido con terceros, estilo UCadetes
        * @default false
        */
        fromOthers?: boolean;
    };

    /**
     * Es un map que vincula un código con un businessId
     * Por ejemplo: 
     * tableMenu: {
     *   BBC: "BBCPOCITOS",
     *   BBCLP: "BBCLASPIEDRAS"   
     * }
     */
    tableMenu?: Record<string, string>;

    /**
    * @nullable
    */
    locale?: LocaleInfo | null;

    /**
    * Si el grupo tiene permitido actualización masiva o no
    * @default false
    */
    allowMasiveUpdate?: boolean;

    /**
     * Si el grupo comparte la carpeta de imagenes
     * @default false
     */
    forceSharedImagesFolder?: boolean;
}