import { Inject, Injectable, NgZone } from '@angular/core';

import {
  doc,
  collection,
  getFirestore,
  Firestore,
  DocumentReference,
  DocumentData,
  Query,
  query,
  getDocFromServer,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  onSnapshot,
  Unsubscribe,
  GeoPoint,
  QuerySnapshot,
  getDocsFromServer,
  FirestoreDataConverter,
  PartialWithFieldValue,
  WithFieldValue,
  SetOptions,
  SnapshotOptions,
  CollectionReference,
  serverTimestamp,
  addDoc,
  setDoc,
  getCountFromServer,
  QueryConstraint,
  QueryCompositeFilterConstraint,
  collectionGroup
} from 'firebase/firestore';

import { doc as docSnapshots, collection as collectionSnapshots } from 'rxfire/firestore';

//FirestoreCursor
import { FirestoreCursor } from './firestore-cursor';


//Observable
import { Observable, filter, map, tap } from 'rxjs';
import { callQueryFn, QueryFn } from './query-fn';
import { DEBUG } from '@config/config';
import { onSnapshotsInSync } from '@firebase/firestore';
import { collectionData, docData } from 'rxfire/firestore';

export type T_Source = 'cache' | 'server' | 'default';

export class GetOptions {
  cursor?: FirestoreCursor;
  source?: T_Source;
}

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
  private firestore: Firestore;

  constructor(
    @Inject(DEBUG) private debug: boolean,
    private ngZone: NgZone) {
    this.firestore = getFirestore();
  }

  public doc<T>(path: string): DocumentReference<T> {
    return doc(this.firestore, path).withConverter<T>(this.defaultConverter<T>());
  }

  public colRef<T>(path: string): CollectionReference<T> {
    return collection(this.firestore, path).withConverter<T>(this.defaultConverter<T>())
  }

  // public col<T>(path: string, queryFn?: QueryFn): Query<T> {
  //   const _col = collection(this.firestore, path).withConverter<T>(this.defaultConverter<T>());

  //   const queryConstraints = callQueryFn(queryFn);
  //   if (queryConstraints.length > 0) {
  //     return query<T, DocumentData>(_col, ...queryConstraints);
  //   }

  //   return _col
  // }

  public col<T>(path: string, queryFn?: QueryFn): Query<T> | CollectionReference<T> {
    const _col = collection(this.firestore, path).withConverter<T>(this.defaultConverter<T>());

    const queryConstraints = callQueryFn(queryFn);

    if (queryConstraints.length === 0) {
      return _col;
    }

    // Separamos las constraints normales de las compuestas (OR/AND)
    const standardConstraints: QueryConstraint[] = [];
    const compositeFilters: QueryCompositeFilterConstraint[] = [];

    queryConstraints.forEach(constraint => {
      if (constraint.type === 'or' || constraint.type === 'and') {
        compositeFilters.push(constraint);
      } else {
        standardConstraints.push(constraint as QueryConstraint);
      }
    });

    let q: Query<T> = _col;

    // Aplicamos primero las constraints estándar
    if (standardConstraints.length > 0) {
      q = query(q, ...standardConstraints);
    }

    // Luego aplicamos los filtros compuestos uno por uno
    compositeFilters.forEach(filter => {
      q = query(q, filter);
    });

    return q;
  }

  public colGroup<T>(path: string, queryFn?: QueryFn): Query<T> | CollectionReference<T> {
    const _col = collectionGroup(this.firestore, path).withConverter<T>(this.defaultConverter<T>());

    const queryConstraints = callQueryFn(queryFn);

    if (queryConstraints.length === 0) {
      return _col;
    }

    // Separamos las constraints normales de las compuestas (OR/AND)
    const standardConstraints: QueryConstraint[] = [];
    const compositeFilters: QueryCompositeFilterConstraint[] = [];

    queryConstraints.forEach(constraint => {
      if (constraint.type === 'or' || constraint.type === 'and') {
        compositeFilters.push(constraint);
      } else {
        standardConstraints.push(constraint as QueryConstraint);
      }
    });

    let q: Query<T> = _col;

    // Aplicamos primero las constraints estándar
    if (standardConstraints.length > 0) {
      q = query(q, ...standardConstraints);
    }

    // Luego aplicamos los filtros compuestos uno por uno
    compositeFilters.forEach(filter => {
      q = query(q, filter);
    });

    return q;
  }

  // Return an Observable
  public doc$<T>(path: string, tag?: string): Observable<T> {
    // if(this.debug) {
    //   console.log(`NgZone ANTES doc$ = ${NgZone.isInAngularZone()}`);
    // }

    return docData(
      this.doc<T>(path)
    ).pipe(
      //observeOn(this.schedulers.insideAngular),
      tap((_doc) => {
        // if(this.debug) {
        //   console.log(`NgZone DESPUES doc$ = ${NgZone.isInAngularZone()} ${tag ?? ''}`);
        // }
      })
    );
  }

  // Return an Promise
  public docOnce<T>(path: string): Promise<T> {
    return getDocFromServer<T, DocumentData>(
      this.doc<T>(path)
    ).then(ds => {
      return ds.exists() ? ds.data() : null;
    })
  }

  public docWithId<T>(path: string, tag?: string): Promise<T> {
    // if(this.debug) {
    //   console.log(`NgZone ANTES docWithId = ${NgZone.isInAngularZone()}`);
    // }

    return getDocFromServer<T, DocumentData>(
      this.doc<T>(path)
    ).then(ds => {
      // if(this.debug) {
      //   console.log(`NgZone DESPUES docWithId = ${NgZone.isInAngularZone()} ${tag ?? ''}`);
      // }

      return ds.exists() ? this.createDataWithId<T>(ds) : null;
    });
  }

  public docWithId$<T>(ref: string, source?: T_Source, tag?: string): Observable<T> {
    return docSnapshots<T>(
      this.doc<T>(ref)
    ).pipe(
      filter((ds: DocumentSnapshot<T>) => this.filterSnapshotBySource(ds, source)),
      tap(ds => {
        if (this.debug && tag) {
          console.log(`docWithId$ TAG = ${tag}`, ds);
        }
      }),
      map((ds: DocumentSnapshot<T>) => {
        return ds.exists() ? this.createDataWithId<T>(ds) : null;
      })
    )
  }

  public col$<T>(ref: string, queryFn?: QueryFn): Observable<T[]> {
    return collectionData<T>(
      this.col<T>(ref, queryFn)
    );
  }

  public colGroup$<T>(ref: string, queryFn?: QueryFn): Observable<T[]> {
    return collectionData<T>(
      this.colGroup<T>(ref, queryFn)
    );
  }

  public colWithIds$<T>(path: string, queryFn?: QueryFn, options?: GetOptions): Observable<T[]> {
    const { cursor, source } = options || {};
    const queryFnCursor = this.getQueryFnFromCursor(queryFn, cursor);

    return collectionSnapshots(
      this.col<T>(path, queryFnCursor)
    ).pipe(
      filter((qdsArr: QueryDocumentSnapshot<T>[]) => this.filterBySource(qdsArr, source)),
      map(qdsArr => {
        this.updateCursor<T>(qdsArr, cursor);
        return qdsArr.map(qds => this.createDataWithId<T>(qds));
      })
    );
  }

  /**
  * Esta operación solo difiere de  colWithIds$ en que si la colección que devuelve es vacía,
  * aún puedo detectar si esa colección es fromCanche o no, ya que el atributo está a nivel del snapshot y no
  * en cada uno de los elementos.
  */
  public colWithIds2$<T>(path: string, queryFn?: QueryFn, options?: GetOptions, tag?: string): Observable<T[]> {
    const { cursor, source } = options || {};
    const queryFnCursor = this.getQueryFnFromCursor(queryFn, cursor);


    return new Observable(subscribe => {
      const subscripton = onSnapshot(
        this.col<T>(path, queryFnCursor),
        { includeMetadataChanges: true },
        (snapshot) => {

          if (this.filterSnapshotBySource(snapshot, source)) {
            const docs = snapshot
              .docs
              .map((qds) => this.createDataWithId<T>(qds));

            if (this.debug && tag) {
              console.log(tag, snapshot.metadata);
              console.log(tag, docs);
            }

            subscribe.next(docs);
          }
        }
      );

      return () => {
        subscripton();
      }
    });
  }

  public colWithIds<T>(ref: string, queryFn?: QueryFn, tag?: string): Promise<T[]> {
    return getDocsFromServer(
      this.col<T>(ref, queryFn)
    ).then((actions: QuerySnapshot<T>) => {
      if (this.debug && tag) {
        console.log(tag, actions.metadata, actions.docs);
      }

      return actions.docs.map(doc => {
        return this.createDataWithId<T>(doc);
      });
    })
  }

  public set<T = unknown>(ref: string, data: T, addCreatedAt: boolean = false, addUpdatedAt: boolean = false): Promise<void> {
    const toSet: any = {
      ...data
    };

    const timestamp = serverTimestamp();
    if (addCreatedAt) {
      toSet.createdAt = timestamp;
    }

    if (addUpdatedAt) {
      toSet.updatedAt = timestamp;
    }

    return setDoc(
      this.doc(ref),
      toSet
    );
  }

  public add<T = unknown>(pathCol: string, data: T, addCreatedAt: boolean = false, addUpdatedAt: boolean = false): Promise<DocumentReference<T>> {
    const toAdd: any = {
      ...data
    };

    const timestamp = serverTimestamp();
    if (addCreatedAt) {
      toAdd.createdAt = timestamp;
    }

    if (addUpdatedAt) {
      toAdd.updatedAt = timestamp;
    }

    return addDoc<T, DocumentData>(
      this.colRef<T>(pathCol),
      toAdd
    );
  }

  public async getCountFromServer(path: string, queryFn?: QueryFn): Promise<number> {
    const queryFnCursor = this.getQueryFnFromCursor(queryFn);

    const result = await getCountFromServer(this.col<unknown>(path, queryFnCursor));
    return result ? result.data().count : 0;
  }

  public inSync(onSync: () => void): Unsubscribe {
    return onSnapshotsInSync(this.firestore, onSync);
  }

  public toGeopoint(latlng: { lat: number, lng: number }): GeoPoint {
    return new GeoPoint(latlng.lat, latlng.lng);
  }

  public toGeopoints(latlngs: { lat: number, lng: number }[]): GeoPoint[] {
    return latlngs && latlngs.map(latlng => this.toGeopoint(latlng));
  }

  public fromGeopoint(geopoint: GeoPoint): { lat: number, lng: number } {
    return {
      lat: geopoint.latitude,
      lng: geopoint.longitude
    };
  }

  //privates
  private getQueryFnFromCursor(queryFn?: QueryFn, cursor?: FirestoreCursor): QueryFn {
    if (queryFn && cursor) {
      return cursor.getQueryFnConstraint(queryFn);
    }

    return queryFn;
  }

  private filterBySource<T>(qdsArr: QueryDocumentSnapshot<T>[], source?: T_Source): boolean {
    if (source) {
      if (source === 'default') {
        return true;
      }

      if (source === 'server') {
        return qdsArr.every(qds => qds.metadata.fromCache === false);
      }

      if (source === 'cache') {
        return qdsArr.every(qds => qds.metadata.fromCache === true);
      }
    }

    return true;
  }

  private filterSnapshotBySource<T>(qsArr: QuerySnapshot<T> | DocumentSnapshot<T>, source?: T_Source): boolean {
    if (source) {
      if (source === 'default') {
        return true;
      }

      if (source === 'server') {
        return qsArr.metadata.fromCache === false;
      }

      if (source === 'cache') {
        return qsArr.metadata.fromCache === true;
      }
    }

    return true;
  }

  private updateCursor<T>(updater: QueryDocumentSnapshot<T>[], cursor?: FirestoreCursor) {
    if (cursor) {
      cursor.update(updater);
    }
  }

  private createDataWithId<T = DocumentData>(docSnapshot: QueryDocumentSnapshot<T> | DocumentSnapshot<T>): T {
    return Object.assign({ id: docSnapshot.id }, docSnapshot.data());
  }

  private defaultConverter<T>() {
    return new DefaultConverterClass<T>();
  }
}

class DefaultConverterClass<T> implements FirestoreDataConverter<T> {
  toFirestore(modelObject: WithFieldValue<T>): DocumentData;
  toFirestore(modelObject: PartialWithFieldValue<T>, options: SetOptions): DocumentData;
  toFirestore(modelObject: any, options?: any) {
    return modelObject;
  }

  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options?: SnapshotOptions): T {
    return snapshot.data() as T;
  }
}
