import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Network } from '@ionic-native/network/ngx';
import { environment } from '@environments/environment';
import { loadMessages, locale } from 'devextreme/localization';

//modules
import { IonicStorageModule } from '@ionic/storage-angular';
import { HttpClientModule } from '@angular/common/http';

//font-awesome
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

//global handler
import { GlobalErrorHandlerService } from '@services/global-error-service/global-error-handler-service';

//service worker
import { ServiceWorkerModule } from '@angular/service-worker';

// firebase
import { initializeFirestore } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';

import {
  faSignInAlt,
  faSignOutAlt,
  faCircle,
  faHistory,
  faHeart,
  faUserAlt,
  faBars,
  faListAlt,
  faSearch,
  faConciergeBell,
  faAt,
  faCertificate,
  faInfo,
  faFilter,
  faPhoneSquare,
  faInfoSquare,
  faCheckSquare,
  faCheckCircle,
  faEdit,
  faBell,
  faCheck,
  faExchangeAlt,
  faObjectGroup,
  faObjectUngroup,
  faMinusSquare,
  faPlusSquare,
  faPlusCircle,
  faPuzzlePiece,
  faArrowSquareLeft,
  faArrowSquareRight,
  faMap,
  faPenSquare,
  faTimesSquare,
  faLineColumns,
  faCogs as fasCogs,
  faChartLine,
  faShareAll,
  faGiftCard,
  faClock as fasClock,
  faStore,
  faStoreSlash,
  faTrash,
  faBrakeWarning,
  faBullseyeArrow,
  faEye,
  faFileChartLine,
  faChartBar,
  faInfoCircle,
  faUser,
  faUsersGear,
  faImage as fasImage,
  faCloudArrowUp,
  faGlobe,
  faList,
  faTriangleExclamation,
  faScrewdriverWrench,
  faUserChef
} from '@fortawesome/pro-solid-svg-icons';

import {
  faHeart as farHeart,
  faHistory as farHistory,
  faClock,
  faPrint,
  faHourglassHalf,
  faAngleDown,
  faThumbsDown,
  faFileEdit,
  faAddressBook,
  faMapMarkerQuestion,
  faMapMarkerCheck,
  faShippingFast,
  faDollarSign,
  faShoppingCart,
  faHome,
  faCogs,
  faCog,
  faFileCheck,
  faCommentDots,
  faBadgeDollar,
  faBadgePercent,
  faInfoCircle as farInfoCircle,
  faBoxCheck,
  faMotorcycle,
  faBell as farBell,
  faCheck as farCheck,
  faTag,
  faVolumeUp,
  faVolumeMute,
  faFlag,
  faFileAlt,
  faRobot,
  faText,
  faRepeat,
  faGiftCard as farGiftCard,
  faCalendarAlt,
  faCalendarDay,
  faLayerGroup,
  faImage,
  faPlus,
  faPaperPlane,
  faEnvelopeOpenText,
  faRedo,
  faGift,
  faCopy
} from '@fortawesome/pro-regular-svg-icons';

// DiHola
import { DiHolaBackendComponent } from './app.component';

//SideMenu
import { MySideMenuDesktop } from '@components/my-side-menu/my-side-menu-desktop';

// Routing
import { AppRoutingModule } from './app-routing.module';

// config
import {
  DIHOLA_WEBSITE_VALUE,
  DIHOLA_WEBSITE,
  RESPONSIVE_UMBRAL_VALUE,
  RESPONSIVE_UMBRAL,
  BROWSER_LOCALE_INFO_VALUE,
  BROWSER_LOCALE_INFO,
  DEFAULT_MIN_HOUR_VALUE,
  DEFAULT_MIN_HOUR,
  GOOGLE_MAP_API_KEY_VALUE,
  GOOGLE_MAP_API_KEY,
  VERSION_APP_VALUE,
  VERSION_APP,
  SASS_COLORS,
  SASS_COLORS_VALUE,
  DEBUG_VALUE,
  DEBUG,
  LOG_TOKEN_VALUE,
  LOG_TOKEN,
  SYSTEM_SERVICE_INTENTS_VALUE,
  SYSTEM_SERVICE_INTENTS,
  SYSTEM_SERVICE_TIMEOUT_VALUE,
  SYSTEM_SERVICE_TIMEOUT,
  DEFAULT_DELIVERY_TIMES_VALUE,
  DEFAULT_DELIVERY_TIMES,
  MP3_SOUND_VALUE,
  MP3_SOUND,
  MP3_DISCONNECTED_WARN_VALUE,
  MP3_DISCONNECTED_WARN,
  MP3_COMPLAINT_WARN_VALUE,
  MP3_BOOKTABLE_WARN_VALUE,
  MP3_BOOKTABLE_WARN,
  MP3_COMPLAINT_WARN,
  ORDER_SOUND_TIMEOUT_VALUE,
  ORDER_SOUND_TIMEOUT,
  COMPLAINT_SOUND_TIMEOUT_VALUE,
  COMPLAINT_SOUND_TIMEOUT,
  BOOKTABLE_SOUND_TIMEOUT_VALUE,
  BOOKTABLE_SOUND_TIMEOUT,
  PRINT_CONTAINER_ID_VALUE,
  PRINT_CONTAINER_ID,
  MAX_LENGTH_LONG_DETAIL_VALUE,
  MAX_LENGTH_LONG_DETAIL,
  ddMMHHmmss_Value,
  ddMMHHmmss,
  ddMMyyyy_Value,
  ddMMyyyy,
  ddMMyyyyHHmm_Value,
  ddMMyyyyHHmm,
  ddMMyyyyHHmmss_Value,
  ddMMyyyyHHmmss,
  MMyyyy_Value,
  MMyyyy,
  ddMMMM_Value,
  ddMMMM,
  ddMM_Value,
  ddMM,
  EEEEd_Value,
  EEEEd,
  EEEEddMMMM_Value,
  EEEEddMMMM,
  Format_ddMMMMddMMMM_Value,
  Format_ddMMMMddMMMM,
  Format_ddddMMMM_Value,
  Format_ddddMMMM,
  Capitalize_ddMMMMddMMMM_Value,
  Capitalize_ddMMMMddMMMM,
  Capitalize_ddddMMMM_Value,
  Capitalize_ddddMMMM,
  Capitalize_EEEEddMMMM_Value,
  Capitalize_EEEEddMMMM,
  Capitalize_ddMMMM_Value,
  Capitalize_ddMMMM,
  DEFAULT_MIN_WAITING_TAKEAWAY_TIME_VALUE,
  DEFAULT_MIN_WAITING_TAKEAWAY_TIME,
  RECONNECTION_AUDIO_WARN_SECONDS_VALUE,
  RECONNECTION_AUDIO_WARN_SECONDS,
  RELOAD_SECONDS_VALUE,
  RELOAD_SECONDS,
  DEFAULT_HISTORY_DAYS_VALUE,
  DEFAULT_HISTORY_DAYS,
  DEFAULT_COMPLAINTS_DAYS_VALUE,
  DEFAULT_COMPLAINTS_DAYS,
  WEB_PUSH_TAG_NAME,
  WEB_PUSH_TAG_NAME_VALUE,
  DEFAULT_MAX_DATE_VALUE,
  DEFAULT_MAX_DATE,
  WAITING_FOR_SYNC_TIMEOUT_VALUE,
  WAITING_FOR_SYNC_TIMEOUT,
  REPORTS_PRODUCTS_COUNT_URL_VALUE,
  REPORTS_PRODUCTS_COUNT_URL,
  CALLABLE_2ND_GEN_BASE_URL_VALUE,
  CALLABLE_2ND_GEN_BASE_URL,
  CALLABLE_GENERATION_VERSION_VALUE,
  CALLABLE_GENERATION_VERSION,
  POS_DOMAIN_VALUE,
  POS_DOMAIN,
  SUNMI_PORT_VALUE,
  SUNMI_PORT,
  SUNMI_HOST_VALUE,
  SUNMI_HOST,
  AUTOMATIC_OPTIN_VOUCHER_VALUE,
  AUTOMATIC_OPTIN_VOUCHER,
  AUTO_ENABLED_WHEN_PRODUCTS_VALUE,
  AUTO_ENABLED_WHEN_PRODUCTS,
  AUTO_ENABLED_WHEN_OPEN_CLOSE_VALUE,
  AUTO_ENABLED_WHEN_OPEN_CLOSE
} from '@config/config';

const app = initializeApp(environment.firebaseConfig);
const settings = {};
const firestore = initializeFirestore(app, settings);

@NgModule({
  declarations: [DiHolaBackendComponent],
  imports: [
    BrowserModule,
    // BrowserTransferStateModule es necesario para devexpress sino tira error que 
    // no encuentra TransferState
    // BrowserTransferStateModule,
    MySideMenuDesktop,
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    FontAwesomeModule,
    HttpClientModule,
    IonicModule.forRoot({
      mode: 'md',
      backButtonText: '--',
      innerHTMLTemplatesEnabled: true,
      // inputShims: true <-- no se que es
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.enablePWA,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    Geolocation,
    Network,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    { provide: DIHOLA_WEBSITE, useValue: DIHOLA_WEBSITE_VALUE },
    { provide: SASS_COLORS, useValue: SASS_COLORS_VALUE },
    { provide: DEFAULT_MIN_HOUR, useValue: DEFAULT_MIN_HOUR_VALUE },
    { provide: RESPONSIVE_UMBRAL, useValue: RESPONSIVE_UMBRAL_VALUE },
    { provide: GOOGLE_MAP_API_KEY, useValue: GOOGLE_MAP_API_KEY_VALUE },
    { provide: VERSION_APP, useValue: VERSION_APP_VALUE },
    { provide: DEBUG, useValue: DEBUG_VALUE },
    { provide: LOG_TOKEN, useValue: LOG_TOKEN_VALUE },
    { provide: SYSTEM_SERVICE_INTENTS, useValue: SYSTEM_SERVICE_INTENTS_VALUE },
    { provide: SYSTEM_SERVICE_TIMEOUT, useValue: SYSTEM_SERVICE_TIMEOUT_VALUE },
    { provide: DEFAULT_DELIVERY_TIMES, useValue: DEFAULT_DELIVERY_TIMES_VALUE },
    { provide: MP3_SOUND, useValue: MP3_SOUND_VALUE },
    { provide: MP3_DISCONNECTED_WARN, useValue: MP3_DISCONNECTED_WARN_VALUE },
    { provide: MP3_COMPLAINT_WARN, useValue: MP3_COMPLAINT_WARN_VALUE },
    { provide: MP3_BOOKTABLE_WARN, useValue: MP3_BOOKTABLE_WARN_VALUE },
    { provide: ORDER_SOUND_TIMEOUT, useValue: ORDER_SOUND_TIMEOUT_VALUE },
    { provide: COMPLAINT_SOUND_TIMEOUT, useValue: COMPLAINT_SOUND_TIMEOUT_VALUE },  
    { provide: BOOKTABLE_SOUND_TIMEOUT, useValue: BOOKTABLE_SOUND_TIMEOUT_VALUE },  
    { provide: PRINT_CONTAINER_ID, useValue: PRINT_CONTAINER_ID_VALUE },
    { provide: BROWSER_LOCALE_INFO, useValue: BROWSER_LOCALE_INFO_VALUE },
    { provide: ddMMHHmmss, useValue: ddMMHHmmss_Value },
    { provide: ddMMyyyy, useValue: ddMMyyyy_Value },
    { provide: ddMMyyyyHHmm, useValue: ddMMyyyyHHmm_Value },
    { provide: ddMMyyyyHHmmss, useValue: ddMMyyyyHHmmss_Value },
    { provide: MMyyyy, useValue: MMyyyy_Value },
    { provide: ddMMMM, useValue: ddMMMM_Value },
    { provide: ddMM, useValue: ddMM_Value },
    { provide: EEEEd, useValue: EEEEd_Value },
    { provide: EEEEddMMMM, useValue: EEEEddMMMM_Value },
    { provide: Format_ddMMMMddMMMM, useValue: Format_ddMMMMddMMMM_Value },
    { provide: Format_ddddMMMM, useValue: Format_ddddMMMM_Value },
    { provide: Capitalize_ddMMMMddMMMM, useValue: Capitalize_ddMMMMddMMMM_Value },
    { provide: Capitalize_ddddMMMM, useValue: Capitalize_ddddMMMM_Value },
    { provide: Capitalize_EEEEddMMMM, useValue: Capitalize_EEEEddMMMM_Value },
    { provide: Capitalize_ddMMMM, useValue: Capitalize_ddMMMM_Value },
    { provide: RECONNECTION_AUDIO_WARN_SECONDS, useValue: RECONNECTION_AUDIO_WARN_SECONDS_VALUE },
    { provide: RELOAD_SECONDS, useValue: RELOAD_SECONDS_VALUE },
    { provide: DEFAULT_HISTORY_DAYS, useValue: DEFAULT_HISTORY_DAYS_VALUE },
    { provide: DEFAULT_COMPLAINTS_DAYS, useValue: DEFAULT_COMPLAINTS_DAYS_VALUE },
    { provide: WEB_PUSH_TAG_NAME, useValue: WEB_PUSH_TAG_NAME_VALUE },
    { provide: DEFAULT_MAX_DATE, useValue: DEFAULT_MAX_DATE_VALUE },
    { provide: MAX_LENGTH_LONG_DETAIL, useValue: MAX_LENGTH_LONG_DETAIL_VALUE },
    { provide: DEFAULT_MIN_WAITING_TAKEAWAY_TIME, useValue: DEFAULT_MIN_WAITING_TAKEAWAY_TIME_VALUE },
    { provide: WAITING_FOR_SYNC_TIMEOUT, useValue: WAITING_FOR_SYNC_TIMEOUT_VALUE },
    { provide: REPORTS_PRODUCTS_COUNT_URL, useValue: REPORTS_PRODUCTS_COUNT_URL_VALUE },
    { provide: CALLABLE_2ND_GEN_BASE_URL, useValue: CALLABLE_2ND_GEN_BASE_URL_VALUE },
    { provide: CALLABLE_GENERATION_VERSION, useValue: CALLABLE_GENERATION_VERSION_VALUE },
    { provide: POS_DOMAIN, useValue: POS_DOMAIN_VALUE },
    { provide: SUNMI_PORT, useValue: SUNMI_PORT_VALUE },
    { provide: SUNMI_HOST, useValue: SUNMI_HOST_VALUE },
    { provide: AUTOMATIC_OPTIN_VOUCHER, useValue: AUTOMATIC_OPTIN_VOUCHER_VALUE },
    { provide: AUTO_ENABLED_WHEN_PRODUCTS, useValue: AUTO_ENABLED_WHEN_PRODUCTS_VALUE },
    { provide: AUTO_ENABLED_WHEN_OPEN_CLOSE, useValue: AUTO_ENABLED_WHEN_OPEN_CLOSE_VALUE },
  ],
  bootstrap: [DiHolaBackendComponent],
})
export class AppModule {
  constructor(
    library: FaIconLibrary) {
    library.addIcons(
      faCheck,
      faPrint,
      faHourglassHalf,
      faSignInAlt,
      faSignOutAlt,
      faCircle,
      faHistory,
      farHistory,
      faHeart,
      farHeart,
      faUserAlt,
      faAngleDown,
      faBars,
      faListAlt,
      faSearch,
      faClock,
      faConciergeBell,
      faThumbsDown,
      faFileEdit,
      faAddressBook,
      faAt,
      faCertificate,
      faInfo,
      faFilter,
      faPhoneSquare,
      faInfoSquare,
      faEdit,
      faBell,
      farBell,
      farCheck,
      faCheckSquare,
      faCheckCircle,
      faMapMarkerQuestion,
      faMapMarkerCheck,
      faShippingFast,
      faDollarSign,
      faShoppingCart,
      faHome,
      faCogs,
      faCog,
      faCommentDots,
      faFileCheck,
      faBadgeDollar,
      faBadgePercent,
      farInfoCircle,
      faBoxCheck,
      faMotorcycle,
      faExchangeAlt,
      faObjectGroup,
      faObjectUngroup,
      faMinusSquare,
      faPlusSquare,
      faPlusCircle,
      faPuzzlePiece,
      faArrowSquareLeft,
      faArrowSquareRight,
      faMap,
      faTimesSquare,
      faPenSquare,
      fasCogs,
      faLineColumns,
      faTag,
      faVolumeUp,
      faVolumeMute,
      faFlag,
      faFileAlt,
      faRobot,
      faChartLine,
      faShareAll,
      faGiftCard,
      fasClock,
      farGiftCard,
      faText,
      faRepeat,
      faCalendarAlt,
      faCalendarDay,
      faLayerGroup,
      faImage,
      faStore,
      faStoreSlash,
      faPlus,
      faTrash,
      faBrakeWarning,
      faPaperPlane,
      faBullseyeArrow,
      faEye,
      faFileChartLine,
      faChartBar,
      faInfoCircle,
      faUser,
      faEnvelopeOpenText,
      faRedo,
      faUsersGear,
      fasImage,
      faCloudArrowUp,
      faGift,
      faGlobe,
      faList,
      faTriangleExclamation,
      faScrewdriverWrench,
      faCopy,
      faUserChef);

    // https://js.devexpress.com/Documentation/Guide/UI_Components/List/Localization/
    loadMessages({
      "es": {
        "dxList-selectAll": 'Todos',
        "dxDataGrid-editingAddRow": "Agregar"
      }
    });
    locale(navigator.language);
  }
}
