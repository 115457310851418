import { Injectable } from '@angular/core';
import { Collections, Features, OthersSubCollections, SubCollections } from '@config/constants';
import { E_FidelityMode } from '@model/group';
import { OptionalBusinessType } from '@model/types/shared';

@Injectable({
    providedIn: 'root'
  })
export class PathService {
    constructor() {};

    public colUser(): string {
        return Collections.BackendUsers;
    }

    public user(userId: string): string {
        return `${this.colUser()}/${userId}`;
    }

    public colBusiness(): string {
        return Collections.Business;
    }

    public business(businessId: string): string {
        return `${this.colBusiness()}/${businessId}`;
    }

    public businessConfig(businessId: string): string {
        const path = [
            Collections.Business,
            businessId,
            SubCollections.Settings,
            OthersSubCollections.Config,
        ];

        return path.join("/");
    }

    public colCities(groupId: string): string {
        return `${Collections.Groups}/${groupId}/${OthersSubCollections.Cities}`;
    }

    public city(cityCode: string): string {
        return `${Collections.Cities}/${cityCode}`;
    }

    public neighborhood(cityCode: string, neighborhoodCode: string): string {
        return `${Collections.Cities}/${cityCode}/${OthersSubCollections.Neighborhoods}/${neighborhoodCode}`;
    }

    public colNeighborhoods(groupId: string, cityId: string): string {
        return `${Collections.Groups}/${groupId}/${OthersSubCollections.Cities}/${cityId}/${OthersSubCollections.Neighborhoods}`;
    }

    public colProducts(businessId: string): string {
        return `${Collections.Business}/${businessId}/${SubCollections.Products}`;
    }

    public product(businessId: string, productId: string): string {
        return `${this.colProducts(businessId)}/${productId}`;
    }

    public colProductSettings(businessId: string): string {
        return `${Collections.Business}/${businessId}/${SubCollections.ProductsSettings}`;
    }

    public productSettings(businessId: string, productId: string): string {
        return `${this.colProductSettings(businessId)}/${productId}`;
    }

    public colCategories(businessId: string): string {
        const col = [
            Collections.Business,
            businessId,
            SubCollections.Settings,
            OthersSubCollections.Collections,
            OthersSubCollections.Categories
        ];

        return col.join('/');
    }

    public colSpecialClosings(businessId: string): string {
        const path = [
            Collections.Business,
            businessId,
            SubCollections.Settings,
            OthersSubCollections.Collections,
            OthersSubCollections.SpecialClosings
        ];

        return path.join("/"); 
    }

    public colDeliveryZones(businessId: string): string {
        return `${Collections.Business}/${businessId}/${SubCollections.DeliveryZones}`;
    }

    public colTemplates(businessId: string): string {
        return `${this.business(businessId)}/${SubCollections.Templates}`;
    }

    public group(groupId: string): string {
        return `${Collections.Groups}/${groupId}`;
    }

    public referral(fidelityMode: E_FidelityMode, groupId: string, businessId: OptionalBusinessType, userId: string): string {
        const path = [
            Collections.Features,
            Features.Referrals,
            fidelityMode === E_FidelityMode.Group ? OthersSubCollections.Groups : OthersSubCollections.Business,
            fidelityMode === E_FidelityMode.Group ? groupId : businessId,
            OthersSubCollections.Referrals,
            userId
        ];

        return path.join("/");
    }

    public referralConfig(fidelityMode: E_FidelityMode, groupId: string, businessId: OptionalBusinessType): string {
        const path = [
            Collections.Features,
            Features.Referrals,
            fidelityMode === E_FidelityMode.Group ? OthersSubCollections.Groups : OthersSubCollections.Business,
            fidelityMode === E_FidelityMode.Group ? groupId : businessId
        ];

        return path.join("/");
    }

    public fidelityConfig(fidelityMode: E_FidelityMode, groupId: string, businessId: OptionalBusinessType): string {
        const path = [
            Collections.Features,
            Features.Fidelity,
            fidelityMode === E_FidelityMode.Group ? OthersSubCollections.Groups : OthersSubCollections.Business,
            fidelityMode === E_FidelityMode.Group ? groupId : businessId
        ];

        return path.join("/");
    }

    public voucher(voucherId: string): string {
        return `${this.colVoucher()}/${voucherId}`;
    }

    public colVoucher(): string {
        const path = [
            Collections.Features,
            Features.Vouchers,
            OthersSubCollections.Vouchers
        ];

        return path.join("/");
    }

    public colOrders(): string {
        return `${Collections.Orders}`;
    }

    public order(orderId: string): string {
        return `${this.colOrders()}/${orderId}`;
    }

    public colReviews(businessId: string): string {
        return `${Collections.Business}/${businessId}/${SubCollections.Reviews}`;
    }

    public batch(batchId: string): string {
        return `${this.colBatches()}/${batchId}`;
    }

    public colBatches(): string {
        const path = [
            Collections.Features,
            Features.Codes,
            OthersSubCollections.Batches
        ];

        return path.join("/");
    }

    public bookatable(bookatableId: string): string {
        return `${this.colBookTables()}/${bookatableId}`;
    }

    public colBookTables(): string {
        const path = [
            Collections.Features,
            Features.BookTables,
            OthersSubCollections.BookTables
        ];

        return path.join("/");
    }

    public code(codeId: string): string {
        return `${this.colCode()}/${codeId}`;
    }

    public colCode(): string {
        const path = [
            Collections.Features,
            Features.Codes,
            OthersSubCollections.Codes
        ];

        return path.join("/");
    }

    public colPromo(): string {
        return Collections.Promos;
    }

    public promo(promoId: string): string {
        return `${this.colPromo()}/${promoId}`;
    }

    public colCampaigns(): string {
        return Collections.Campaigns;
    }

    public campaign(campaignId: string): string {
        return `${this.colCampaigns()}/${campaignId}`;
    }

    public colCampaignsParts(): string {
        return Collections.CampaignsParts;
    }

    public campaignPart(partId: string): string {
        return `${this.colCampaignsParts()}/${partId}`;
    }

    public colGeneratedMessages(): string {
        return Collections.GeneratedMessages;
    }

    public generatedMessage(generatedMessageId: string): string {
        return `${this.colGeneratedMessages()}/${generatedMessageId}`;
    }

    public colWallet(): string {
        return Collections.Wallets;
    }

    public wallet(walletId: string): string {
        return `${this.colWallet()}/${walletId}`;
    }

    public colInvoiceSummary(): string {
        return Collections.InvoiceSummary;
    }
}